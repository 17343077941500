import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import RuledBreak from "../components/RuledBreak"
import { ButtonTrace } from "../components/Button"

const IndexPage = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves Fan Like a Pro"}
      height={400}
      mobile={300}
    >
      <SEO title="Welcome!" keywords={["Eyelashes", "Cali Reeves"]} />
      {/* ----------------------------------------------------------------------- */}
      <div className="row mt-5">
        <div className="col-md-12">
          <RuledBreak>Cali Reeves</RuledBreak>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
      <div className="row">
        <div className="col-md-6 col-lg-4 text-center my-4">
          <h2>See membership options</h2>
          <ButtonTrace to="/education/memberships" btn={3} className="mt-2">
            Memberships
          </ButtonTrace>
        </div>
        <div className="col-md-6 col-lg-4 text-center my-4">
          <h2>Check out the available trainings</h2>
          <ButtonTrace to="/education/trainings" btn={3} className="mt-2">
            Trainings
          </ButtonTrace>
        </div>
        <div className="col-md-12 col-lg-4 text-center my-4">
          <h2>Shop past videos &amp; mini courses</h2>
          <ButtonTrace to="/education/videoss" btn={3} className="mt-2">
            Videos
          </ButtonTrace>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
    </Layout>
  )
}

export const query = graphql`
  query indexQuery {
    header: allFile(filter: { name: { eq: "home_header_1200_791" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default IndexPage
